
export default function Home(props) {
    const {
        activeClass,
        startNewChat,
        brandConfig,
    } = props;

    const articles = brandConfig.apikey === "48ed1d50495946d125a69172540d594d509286618bc939c61e697d55f6a1e54796510e9356d24a800fad8b15369adbea0a6dfd728ed0f635024054ffc06522e4" ?
    [
        {
            url: "https://www.quiklyz.com/blog/maruti-suzuki-swift-vs-maruti-suzuki-baleno",
            title: "Maruti Suzuki Swift VS Maruti Suzuki Baleno: Know Which Is Better",
            image: "https://mmfss-leaseprod-cdn.azureedge.net/vehicleimages/resources/img/leasing/blog/maruti_suzuki_swift_vs_maruti_suzuki_baleno.webp?v=2.1.7",
            excerpt: "Hatchbacks Swift and Baleno have played a vital role in helping Maruti Suzuki cement its position as the largest automobile company in India. It has routinely upgraded both cars to keep up with the market trends and maintain business competitiveness."
        }
    ] :
    [
        {
            url: "https://www.mahindrafinance.com/insights/vehicle-loans/faqs-related-to-pre-owned-cars",
            image: "assets/faqs-related-to-pre-owned-cars-1.webp",
            title: "FAQs Related to Pre-Owned Cars",
            excerpt: "As the name suggests, a used or pre-owned car loan is a facility offered to people who are looking to buy a used or pre-owned car."
        }
    ];

    const suggestions = (brandConfig.prompts || []).map(p => p.text).filter(Boolean);

    return <div data-screen="mc-chat-home" className={"mc-screen mc-chat-home " + activeClass("mc-chat-home")}>
        <div className="mc-chat-contents">
            <div className="mc-chat-intro">
                How may I assist you today?
            </div>
            <div className="mc-chat-suggestions">
                <div className="mc-search" onClick={() => startNewChat()}>
                    Enter your query...
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                         className="mc-search-icon">
                        <path fillRule="evenodd"
                              d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
                              clipRule="evenodd"/>
                    </svg>
                </div>
                <div className="mc-chat-prompts">
                    {
                        suggestions.map((content, index) => {
                            return <div key={"suggestion-"+index} onClick={() => startNewChat(content)}>
                                {content}
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6">
                                    <path fillRule="evenodd"
                                          d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                                          clipRule="evenodd" />
                                </svg>
                            </div>;
                        })
                    }
                </div>
            </div>
            {
                articles.map((article, index) => {
                    return <div className="mc-chat-news" key={"article-"+index}>
                        <a className="mc-article"
                           href={article.url}
                           target="_blank">
                            <img src={article.image} alt=""
                                 className="mc-featured" />
                            <div className="mc-article-content">
                                <div className="mc-article-title">{article.title}</div>
                                <div className="mc-article-excerpt">{article.excerpt}
                                </div>
                            </div>
                        </a>
                    </div>
                })
            }
        </div>
    </div>
        ;
}
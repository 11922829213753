import {useEffect, useState, useRef} from 'preact/hooks';
import Home from "../screens/home";
import Conversations from "../screens/conversations";
import SingleConversations from "../screens/singleConversation";
import {fetchConversationsHistory, generateId, getPreferences, hexToRGB, makeMessage, sendQueryToAPI} from "../utils";

const App = (props) => {
	const homeScreen = "mc-chat-home";
	const apikey = props?.apikey;
	const [activeScreen, setActiveScreenState] = useState(homeScreen);
	const [conversations, setConversations] = useState([]);
	const [brandConfig, setBrandConfigData] = useState({});
	const [brandConfigLoaded, setBrandConfigLoaded] = useState(false);

	const setBrandConfig = (config) => {
		setBrandConfigLoaded(true);
		setBrandConfigData(config);
	}

	const [lastScreen, setLastScreen] = useState(homeScreen);
	const [activeChat, setActiveChat] = useState({
		id: generateId(),
		messages: [],
	});
	const [newChat, openNewChat] = useState("");
	const [isOpen, setOpenState] = useState(false);
	const [inputFocused, setInputFocusState] = useState(false);

	const setInputFocused = (focused) => {
		setInputFocusState(focused);
		if(focused){
			if(open){
				document.body.classList.add("mcb-focused");
			}else{
				document.body.classList.remove("mcb-focused");
			}
		}
	}

	const setOpen = (open) => {
		if(open){
			document.body.classList.add("mcb-chat-open");
		}else{
			document.body.classList.remove("mcb-chat-open");
		}
		setOpenState(open);
	}

	const containerRef = useRef();
	const inputRef = useRef();
	const chatWindowRef = useRef();

	const scrollToBottom = () => {
		setTimeout(() => {
			if(containerRef && containerRef?.current){
				const el = containerRef.current;
				el.scrollTop = el?.scrollHeight - el?.offsetHeight;
			}
		},50);
	};

	useEffect(() => {
		setConversations((list) =>{
			return [...list].map(item => {
				if(item.id === activeChat.id){
					return {...activeChat};
				}
				return item;
			});
		});
	}, [activeChat]);

	useEffect(() => {
		getPreferences(apikey).then(preferences => {
			if(preferences){
				setBrandConfig({
					...preferences,
					apikey,
				});
			}
		});
	}, [])

	const setActiveScreen = screen => {
		if(screen !== activeScreen){
			setLastScreen(activeScreen);
			setActiveScreenState(screen);
		}
	}

	const updateMessageContent = (id, content) => {
		setActiveChat(chat => {
			const updatedActiveChat = {
				...chat,
				messages: chat.messages.map(message => {
					if(message.id === id){
						message.content = content;
						message.loading = false;
					}
					return message;
				})
			};
			return updatedActiveChat;
		});

		scrollToBottom();
	}

	const sendMessage = (content) => {
		const history = [...(activeChat?.messages || [])];
		const newChat = [
			...history,
			makeMessage(content, "user"),
		];

		//Bot reply
		const id = generateId();
		newChat.push(makeMessage("", "bot", {id}))

		sendQueryToAPI(activeChat.id, content, history, apikey, (content) => {
			updateMessageContent(id, content)
		},(response) => {
			console.log({response});
			// updateMessageMetaData(id, response);
			// setChatLoading(false);
		})
		scrollToBottom();

		setActiveChat(chat => {
			return {
				...chat,
				messages: newChat
			};
		});
	}

	const openConversation = (id) => {
		setActiveScreen("mc-chat-messages");
		const activeConversation = conversations.find(c => c.id === id);
		if(activeConversation){
			setActiveChat(activeConversation);
		}
	}

	useEffect(() => {
		fetchConversationsHistory(apikey).then(history => {
			if(history){
				setConversations(history);
			}
		});
	},[])

	function checkFocusElement(){
		setTimeout(() => {
			if(document.activeElement && document.activeElement.classList.contains("mc-focusable")){
				setInputFocused(true);
			}else{
				setInputFocused(false);
			}
		}, 100)
	}

	useEffect(() => {
		if(newChat?.trim()!=="") {
			openConversation(newChat);
		}
	},[newChat])


	const startNewChat = (content) => {
		const c = {
			id: generateId(),
			messages: [],
		};
		const updatedConversions = [
			...conversations,
			c,
		]
		setConversations(updatedConversions);
		setTimeout( () => {
			openNewChat(c.id);
			if(content && content.length){
				setTimeout(() => {
					sendMessage(content);
				}, 50)
			}else{
				setTimeout(() => {
					if(inputRef && inputRef.current){
						inputRef.current.focus();
					}
				}, 100)
			}

		}, 50)
	}

	const activeClass = (screen) => screen === activeScreen ? "active": "";
	const navigateBack = () => setActiveScreen(lastScreen);

	const toggleOpenState = () => setOpen(open => !open);

	return brandConfigLoaded ? <div id="app">
		<div id="js-bot">
			<div className={"mc-chat-container "+(isOpen ? "mc-chat-open" : "")}>
				<div className="mc-chat-widget" onClick={toggleOpenState}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="chat-icon mc-icon">
						<path fillRule="evenodd"
							  d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223zM8.25 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM10.875 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
							  clipRule="evenodd" />
					</svg>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="close-icon  mc-icon">
						<path fillRule="evenodd"
							  d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
							  clipRule="evenodd" />
					</svg>
				</div>
				<div className={"mc-chat-window "+(inputFocused ? " mc-focused " : "")} ref={chatWindowRef}>
					<div className="mc-chat-top" />
					<div className="mc-chat-header">
						<div className={"mc-back-button "+(lastScreen && activeScreen !== homeScreen ? "active" : "")} onClick={navigateBack}>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5">
								<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
							</svg>
						</div>
						{/*<img className="mc-chat-logo" src="assets/MahindraLogoSquare.jpeg"/>*/}
						<img className="mc-chat-logo" src={brandConfig.logo} />
						{brandConfig.organization}
					</div>

					<Home {...{activeClass,startNewChat, brandConfig}} />
					{
						activeScreen === "mc-chat-conversations" ? <Conversations {...{openConversation,conversations,activeClass,startNewChat}} /> : null
					}

					{
						activeScreen === "mc-chat-messages" ? <SingleConversations {...{sendMessage,brandConfig,activeChat,activeClass, containerRef, inputRef, checkFocusElement}} /> : null
					}

					<div className="mc-navigation">
						<div className={"mc-nav-link "+activeClass("mc-chat-home")}  onClick={() => setActiveScreen("mc-chat-home")}>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
								 className="mc-icon-outline">
								<path strokeLinecap="round" strokeLinejoin="round"
									  d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
							</svg>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="mc-icon-filled">
								<path fillRule="evenodd"
									  d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
									  clipRule="evenodd" />
							</svg>

						</div>
						<div className={"mc-nav-link "+activeClass("mc-chat-conversations")}  onClick={() => setActiveScreen("mc-chat-conversations")}>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
								 className="mc-icon-outline">
								<path strokeLinecap="round" strokeLinejoin="round"
									  d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"/>
							</svg>

							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="mc-icon-filled">
								<path
									d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z"/>
								<path
									d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z"/>
							</svg>
						</div>
						{
							window.innerWidth < 480 ?
								<div className={"mc-nav-link "}  onClick={() => setOpen(false)}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"  className="close-icon  mc-icon-outline">
										<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
									</svg>

								</div> : null
						}
					</div>
				</div>
			</div>
		</div>
	</div> : null;
};

export default App;

import {useState} from "preact/hooks";
import {skipUserIdentification, setUserIdentification, isValidEmail} from "../utils";


const FieldsToCollect = [
    {
        key: "name",
        type: "text",
        label: "Name",
        error: "Please enter your name",
        isCorrect: v => v.length > 0
    },
    {
        key: "email",
        type: "email",
        label: "Email",
        error: "Please enter a valid email address",
        isCorrect: v => v.length > 0 && isValidEmail(v)
    },
    {
        key: "phone",
        type: "tel",
        label: "Phone Number",
        error: "Please provide your contact number",
        isCorrect: v => v.length > 0
    },
];
export default function CollectUserDetails(props){
    const {
        userIdentificationDone,
        setUserIdentificationDone,
        checkFocusElement,
    } = props;
    const [userDetails, setUserDetails] = useState({
        name: "",
        email: "",
        phone: ""
    });

    const [validationError, setShowValidationError] = useState(false);
    const updateUserDetails = (field) => e => {
        setUserDetails(details => {
            return {
                ...details,
                [field]: e.target.value
            };
        });
    }
    const saveUserDetails = () => {
        if(userDetails.name && userDetails.email && isValidEmail(userDetails.email) && userDetails.phone){
            setUserIdentification(userDetails);
            setUserIdentificationDone(true);
            setShowValidationError(false);
        }else{
            setShowValidationError(true);
        }
    }

    const skipIdentification = () => {
        skipUserIdentification();
        setUserIdentificationDone(true);
    }


    return <div className={"mc-user-data-form"}>
        <p>Introduce yourself please</p>
        {
            FieldsToCollect.map(field => {
                const hasError = validationError && !field.isCorrect(userDetails[field.key]);
                return <div key={field.key} className={hasError ? "mc-has-error" : ""}>
                    <input autocomplete="off" className={"mc-focusable"} onFocus={checkFocusElement} onBlur={checkFocusElement} type={field.type} placeholder={field.label} value={userDetails[field.key]} onChange={updateUserDetails(field.key)} />
                    {
                        hasError ? <div className={"mc-error-message"}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                            </svg>
                        </div> : null
                    }
                </div>
            })
        }
        <div className="btn-set">
            <button onClick={saveUserDetails}>Submit</button>
            <button className={"skip"} onClick={skipIdentification}>skip</button>
        </div>
    </div>
}
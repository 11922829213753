import {useEffect, useState} from "preact/hooks";
import ReactMarkdown from "react-markdown";
import {showUserIdentificationMessage} from "../utils";
import CollectUserDetails from "../components/CollectUserDetails";
import BotIcon from "../assets/bot.svg";

export default function SingleConversations(props) {
    const {
        activeClass,
        activeChat,
        starterMessage = "",
        sendMessage,
        containerRef,
        brandConfig,
        inputRef :ref,
        checkFocusElement,
    } = props;

    const toCollectUserData = showUserIdentificationMessage();

    const [message, setMessage] = useState(starterMessage);
    const [userIdentificationDone, setUserIdentificationDone] = useState(!toCollectUserData);

    const scrollToBottom = () => {
        setTimeout(() => {
            if(containerRef && containerRef?.current){
                const el = containerRef.current;
                el.scrollTop = el?.scrollHeight - el?.offsetHeight;
            }
        },50);
    };

    const handleMessageChange = (e) => {
        setMessage(e?.target?.value);
        checkForEnterKey(e);
    }

    const checkForEnterKey = (e) => {
        if(!e.shiftKey && e.key === "Enter"){
            e.preventDefault();
            sendInputMessage(e.target.value);
        }
    };

    const sendInputMessage = (abc) => {
        if((abc).trim()!=="") {
            sendMessage(abc);
        }
        setMessage("");
    }


    useEffect(() => {
        scrollToBottom();
        setTimeout(() => {
            if(ref.current){
                ref.current.focus();
            }
        }, 1000);
    },[])

    return <div data-screen="mc-chat-messages"
                 className={"mc-screen mc-screen-white  mc-chat-messages " + activeClass("mc-chat-messages")}>
        <div className="mc-chat-message-log"  ref={containerRef}>
            {/*<div className="mc-day">Yesterday</div>*/}
            {
                ([
                    {
                        content: brandConfig.welcomeMessage || "Welcome to our website! I am an interactive assistant meant to help you with your queries. How may I assist you today?",
                        role: "welcome-message",
                    },
                    ...(toCollectUserData ? [{role: "user-identification"}] : []),
                    ...(activeChat?.messages || [])
                ])?.map((message, index) => {
                    const isUser = message.role === "user";
                    const isWelcomeMessage = message.role === "welcome-message";
                    const messageLength = !isUser && message?.content?.trim().length < 10;
                    return <div key={"message-"+index} className={"mc-message "+(isUser ? "user" : "bot")}>
                        <div className="mc-user-icon">
                            {
                                isUser ?
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"/>
                                    </svg> :
                                    <img src={BotIcon} alt="" className={isWelcomeMessage && toCollectUserData ? "opacity-0" : ""} />
                            }
                        </div>
                        <div className="mc-message-main">
                            {
                                message?.role === "user-identification" ?
                                    <CollectUserDetails {...{userIdentificationDone, setUserIdentificationDone, checkFocusElement}} /> :
                                    <div className="mc-message-text">
                                        {messageLength ? <div className={"message-loader"}><span /><span /><span /></div> : <ReactMarkdown
                                            components={{
                                                a: ({node, ...props}) => <a {...props} target={"_blank"} />,
                                            }}
                                            children={message.content} />}
                                    </div>
                            }
                            {/*<div className="mc-message-time">00:29</div>*/}
                        </div>
                    </div>
                })
            }
        </div>
        <div id="mc-chat-input" className="mc-chat-input">
            <input autocomplete="off" className={"mc-focusable"} onFocus={checkFocusElement} onBlur={checkFocusElement} type="text" value={message} onKeyDown={handleMessageChange} ref={ref} placeholder="Enter your query here..."/>
            <svg onClick={() => sendInputMessage(message)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="mc-icon-filled">
                <path
                    d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z"/>
            </svg>
        </div>
    </div>
}
import { formatDistanceToNow } from "date-fns";
import {useEffect} from "preact/hooks";

export default function Conversations(props) {
    const {
        activeClass,
        conversations,
        openConversation,
        startNewChat,
    } = props;

    useEffect(() => {
        if(conversations.length === 0){
            startNewChat();
        }
    }, [])

    return <div data-screen="mc-chat-conversations"
                className={"mc-screen mc-screen-white mc-chat-conversations " + activeClass("mc-chat-conversations")}>
        <div className="mc-conversation-single" onClick={() => startNewChat()}>
            <div className="mc-conversation-title">
                <div>Start new conversation</div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
        </div>
        {
            conversations.map(chat => {
                if(chat.messages.length){
                    const lastMessage = chat.messages[chat.messages.length  -1];
                    return (<div className="mc-conversation-single" key={chat.id} onClick={() => openConversation(chat.id)}>
                            <div className="mc-conversation-title">
                                <div>{lastMessage.content}</div>
                                <div className="mc-conversations-meta">{lastMessage.role === "user" ? "You" : "Assistant"} • {formatDistanceToNow(lastMessage?.createdAt,{addSuffix: true})}</div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6">
                                <path fillRule="evenodd"
                                      d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                                      clipRule="evenodd" />
                            </svg>
                        </div>
                    );
                }
                return null;
            })
        }
    </div>

}
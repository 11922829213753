import axios from "axios";
export const baseApiURL = "https://ai-chat.eu.stg.makestories.io";

const userIdKey = "chatbot_mf_uid";

export const generateId = function () {
    const thousand = 1000;
    const sixteen = 16;

    const timestamp = ((new Date().getTime() / thousand) | 0).toString(sixteen);
    return (
        timestamp +
        'xxxxxxxxxxxxxxxx'
            .replace(/[x]/g, function () {
                return ((Math.random() * sixteen) | 0).toString(sixteen);
            })
            .toLowerCase()
    );
};

let hasKey = localStorage.getItem(userIdKey);
let userId = hasKey || generateId();
localStorage.setItem(userIdKey, userId);
let userIdentified = localStorage.getItem("userIdentified") == 1;

export function getUID(){ return userId; }
export function showUserIdentificationMessage(){ return !userIdentified; }

function markUserAsIdentified(){
    userIdentified = true;
    localStorage.setItem("userIdentified", 1);
}
export function skipUserIdentification(){
    markUserAsIdentified();
}
export function setUserIdentification(details){
    axios.post(baseApiURL + "/lead/record", {
        ...details,
        sessionId: getUID(),
    }).catch(e => {
        console.error("Could not record user details!", e);
        localStorage.removeItem("userIdentified");
    })
    markUserAsIdentified();
}

export function makeMessage(content = "", role = "user", overrides = {}){
    return {
        id: generateId(),
        role,
        content,
        createdAt: Date.now(),
        ...overrides,
    };
}


window.setDetails = (o) => {
    if(o.userId){
        userId = o.userId;
        localStorage.setItem(userIdKey, userId);
    }
}

export async function sendQueryToAPI(conversationId, message, chatHistory, apikey, appendText, endStream){
    // const history = [];
    // let conv = [];
    // chatHistory.map((message) => {
    //     if(
    //         (message.role === "user" && conv.length === 0) ||
    //         (message.role === "bot" && conv.length === 1)
    //     ){
    //         conv.push(message);
    //     }
    //     if(conv.length === 2){
    //         history.push([...conv]);
    //         conv = [];
    //     }
    // })

    const doneDelimiter = "___DONE___:";
    let streamEnded = false;
    let endStreamStarted = false;
    let doneString = "";
    let messageText = "";

    const checkIfEnded = () => {
        if(streamEnded){
            try{
                endStream(JSON.parse(doneString));
            }catch (e) {
                console.error(e);
                endStream({});
            }
        }else{
            setTimeout(checkIfEnded, 100);
        }
    }

    const sendFilteredText = (filtered) => {
        if(filtered.trim().startsWith(doneDelimiter)){
            const finalResponse = filtered.trim().replace(doneDelimiter, "");
            doneString += finalResponse;
            endStreamStarted = true;
            checkIfEnded();
        }else if(endStreamStarted){
            doneString += filtered;
        }else{
            messageText += filtered;
            console.log({filtered})
            appendText(messageText);
        }
    }

    try{

        const response = await fetch(baseApiURL+"/v2/chatbot/query/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query: message,
                sessionId: getUID(),
                key: apikey,
                conversationId,
                history: chatHistory,
            }),
        });
        const reader = response.body.getReader();



        while (true) {
            const {value, done} = await reader.read();
            if (done) {
                streamEnded = true;
                break;
            }
            const decodedText = new TextDecoder("utf-8").decode(value);
            const filtered = decodedText.split(/id:\s\d+\ndata:\s/g).filter(i => !i.startsWith("id:"))

            filtered.map(
                i =>
                    sendFilteredText(
                        i
                            .replace(new RegExp('\n\n$', "g"), "")
                            .replace(new RegExp('\\ndata:\\s', "g"), "")
                            .replace(/id:\s\d+$/, "")
                            .replace(new RegExp('<br/>', "g"), "\n\n")
                    ));
        }
        return false
    }catch (e) {
        console.error(e);
        sendFilteredText("Some error occurred while fetching data");
        endStream({});
    }
}

export async function fetchConversationsHistory(apiKey){
    try{
        const response = await axios.get(`${baseApiURL}/conversations/list`,{
            params: {
                sessionId: getUID(),
                key: apiKey,
            }
        });
        if(response && response.data){
            return response.data;
        }
    }catch (e) {
        console.error("Error while fetching conversations: ", e);
        return [];
    }
}

export async function getPreferences(apiKey){
    try{
        const response = await axios.get(`${baseApiURL}/preferences`,{
            params: {
                key: apiKey,
            }
        });
        if(response?.data?.settings){
            let preferences = response.data.settings;
            const color = preferences.color || "#e41a35";
            const styleContent = `
.mc-chat-widget, .mc-chat-top, .user .mc-message-main, .message-loader span:before,.mc-user-data-form button {
    background: ${color};
}

.mc-search-icon, .mc-chat-prompts div:hover svg, .mc-nav-link:hover .mc-icon-outline  {
    stroke: ${color};
}

.mc-chat-prompts div:hover, .mc-chat-conversations > div:hover {
    color: ${color};
    background: ${hexToRGB(color, 0.1)};
}
.mc-chat-conversations > div:hover svg{
    stroke: ${color}
}

.mc-nav-link.active .mc-icon-filled {
    fill: ${color}
}
`;
            let style = document.createElement("style");
            style.innerHTML = styleContent;
            document.head.appendChild(style);
            console.log({preferences})
            return {
                ...preferences,
                prompts: response.data.prompts,
            };
        }
    }catch (e) {
        console.error("Error while fetching conversations: ", e);
    }
}

export function isValidEmail(emailAdress){
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (emailAdress.match(regex))
        return true;

    else
        return false;
}


export function hexToRGB(hex, alpha) {

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
        return `rgb(${r}, ${g}, ${b})`;
    }
}